import React, { useState, useContext, useEffect } from 'react';
import mfaApi from '../../api/mfaApi';
import AuthContext from '../../store/authContext';
import swal from 'sweetalert';
import ReactLoading from 'react-loading';
const UpdateEmp = props => {
  //  id,
  // fullname,
  // email,
  // phone,
  // servicetype,
  // status,
  // areapincode,
  // address,
  let id = props.enquiryid;
  let oldresponsedata = props.datares;
  let oldstatus = oldresponsedata.status;
  let oldphone = oldresponsedata.phone;
  let oldjooiningdate = oldresponsedata.joiningdate;
  let oldaadharno = oldresponsedata.adharcardno;
  let oldfullname = oldresponsedata.fullname;
  let oldfullareapincode = oldresponsedata.areapincode;
  let oldemail = oldresponsedata.email;
  let oldservicetype = oldresponsedata.servicetype;
  let oldaddress = oldresponsedata.address;
  let oldremark = oldresponsedata.remark;
  // Set the default value when the component mounts
  const authCtx = useContext(AuthContext);

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [joiningdate, setJoiningdate] = useState('');
  const [pincode, setPincode] = useState('');
  const [aadharno, setAadharno] = useState('');
  const [address, setAddress] = useState('');
  const [status, setStatus] = useState('');
  // const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const [isLoadingdelete, setisLoadingdelete] = useState(false);
  const [servicename, setServicename] = useState('');
  const [remark, setRemark] = useState('');
  const [resdata, setResdata] = useState([]);
  const getServicesDetails = async () => {
    try {
      const response = await mfaApi.get(`/urbanadmin/getservicepublic`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setResdata(response.data.data);
        // setTotal(response.data);
      } else {
        swal(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  const handleChange = event => {
    const selectedValue = event.target.value;
    setServicename(selectedValue);
  };
  useEffect(() => {
    // Place your side effect code here
    // This code will run after the component renders
    getServicesDetails();
    // You can perform cleanup by returning a function
    return () => {};
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, []);
  function reloadPageWithDelay() {
    setTimeout(function () {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }, 2000); // 2000 milliseconds (2 seconds)
  }

  // EJ FILE UPLOAD API

  const updateEnquiryform = async e => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const response = await mfaApi.put(
        `/urbanadmin/updateemployee/${id}`,
        {
          fullname: name,
          phone: phone,
          email: email,
          servicetype: servicename,
          joiningdate: joiningdate,
          areapincode: pincode,
          adharcardno: aadharno,
          address: address,
          status: status,
          remark: remark,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authCtx.token}`,
          },
        },
      );
      if (response.status === 201) {
        swal(response.data.message);
        setIsLoading(false);
        reloadPageWithDelay();
      }
    } catch (error) {
      swal({
        title: '🤦‍♂️',
        text: error.response.data.message,
        type: 'error',
        confirmButtonText: 'Cool',
      });
    }
    setIsLoading(false);
  };
  return (
    <>
      <section className="mx-auto  w-full overflow-hidden">
        {isLoading && (
          <div className="flex items-center justify-center my-5">
            <ReactLoading
              type="spinningBubbles"
              color="#0143E2"
              height={'6%'}
              width={'6%'}
            />
            <p className="flex px-1 font-medium">Updating please wait...</p>
          </div>
        )}
        <div className="mx-auto w-full overflow-hidden">
          <div className="grid grid-cols-6 gap-4 py-2"> </div>
          <div className="shadow overflow-hidden sm:rounded-md">
            <div className="px-4 bg-orange-200 sm:p-4">
              {' '}
              {/* Adjusted padding for better spacing */}
              <div className="grid grid-cols-9 gap-4 py-2">
                {' '}
                {/* Modified grid structure */}
                {/* Full Name Section */}
                <div className="col-span-3 sm:col-span-3 lg:col-span-3">
                  <label
                    htmlFor="notificationmsg"
                    className="block text-sm font-medium  text-gray-700"
                  >
                    Full Name{' '}
                  </label>
                  <input
                    type="text"
                    name="notificationmsg"
                    id="notificationmsg"
                    defaultValue={oldfullname}
                    onChange={e => setName(e.target.value)}
                    className=" block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  ></input>
                </div>
                <div className="col-span-3 sm:col-span-3 lg:col-span-3">
                  <label
                    htmlFor="notificationmsg"
                    className="block text-sm font-medium  text-gray-700"
                  >
                    Phone No
                  </label>
                  <input
                    type="text"
                    name="notificationmsg"
                    id="notificationmsg"
                    defaultValue={oldphone}
                    onChange={e => setPhone(e.target.value)}
                    className=" block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  ></input>
                </div>
                <div className="col-span-3 sm:col-span-3 lg:col-span-3">
                  <label
                    htmlFor="notificationmsg"
                    className="block text-sm font-medium  text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    name="notificationmsg"
                    id="notificationmsg"
                    defaultValue={oldemail}
                    onChange={e => setEmail(e.target.value)}
                    className=" block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  ></input>
                </div>
                <div className="col-span-3 sm:col-span-3 lg:col-span-3">
                  <label className="flex  text-sm font-medium  text-gray-700">
                    Service Type{' '}
                    <p className="flex px-2 text-green-800">{oldservicetype}</p>
                  </label>
                  <div className="relative flex items-center">
                    <select
                      name="serviceType"
                      required
                      className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      defaultValue=""
                      onChange={handleChange} // Attach handler
                    >
                      <option value="" disabled hidden>
                        Select service type
                      </option>
                      {resdata.map(service => (
                        <option key={service.id} value={service.servicename}>
                          {service.servicename}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-span-3 sm:col-span-3 lg:col-span-3">
                  <label
                    htmlFor="notificationmsg"
                    className="flex  text-sm font-medium  text-gray-700"
                  >
                    Joining Date{' '}
                    <div className="text-sm font-normal text-gray-900">
                      {oldjooiningdate
                        ? new Date(oldjooiningdate).toLocaleDateString('en-GB')
                        : 'N/A'}
                    </div>
                  </label>
                  <input
                    type="date"
                    name="notificationmsg"
                    id="notificationmsg"
                    defaultValue={oldjooiningdate}
                    onChange={e => setJoiningdate(e.target.value)}
                    className=" block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  ></input>
                </div>
                <div className="col-span-3 sm:col-span-3 lg:col-span-3">
                  <label
                    htmlFor="notificationmsg"
                    className="block text-sm font-medium  text-gray-700"
                  >
                    Pincode
                  </label>
                  <input
                    type="text"
                    name="notificationmsg"
                    id="notificationmsg"
                    defaultValue={oldfullareapincode}
                    onChange={e => setPincode(e.target.value)}
                    className=" block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  ></input>
                </div>
                <div className="col-span-3 sm:col-span-3 lg:col-span-3">
                  <label
                    htmlFor="notificationmsg"
                    className="block text-sm font-medium  text-gray-700"
                  >
                    Aadhar No
                  </label>
                  <input
                    type="text"
                    name="notificationmsg"
                    id="notificationmsg"
                    defaultValue={oldaadharno}
                    onChange={e => setAadharno(e.target.value)}
                    className=" block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  ></input>
                </div>
                <div className="col-span-3 sm:col-span-3 lg:col-span-3">
                  <label
                    htmlFor="notificationmsg"
                    className="block text-sm font-medium  text-gray-700"
                  >
                    Address
                  </label>
                  <textarea
                    type="text"
                    name="notificationmsg"
                    id="notificationmsg"
                    defaultValue={oldaddress}
                    onChange={e => setAddress(e.target.value)}
                    className=" block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  ></textarea>
                </div>
                <div className="col-span-3 sm:col-span-3 lg:col-span-3">
                  <label
                    htmlFor="status"
                    className="flex text-sm font-semibold text-gray-700"
                  >
                    Previous Status:{' '}
                    <p className="flex px-2 text-black font-semibold pr-2">
                      {oldstatus}
                    </p>
                  </label>
                  <div className=" items-center">
                    <select
                      name="status"
                      id="status"
                      value={oldstatus}
                      onChange={e => setStatus(e.target.value)}
                      className="block w-full shadow-sm sm:text-sm text-blue-900 font-semibold border border-gray-300 rounded-md"
                    >
                      <option value="">Select a status</option>
                      <option value="Active">Active</option>
                      <option value="InActive">InActive</option>
                    </select>
                  </div>
                </div>
                <div className="col-span-3 sm:col-span-3 lg:col-span-3">
                  <label
                    htmlFor="notificationmsg"
                    className="block text-sm font-medium  text-gray-700"
                  >
                    Remark{' '}
                  </label>
                  <textarea
                    type="text"
                    name="notificationmsg"
                    id="notificationmsg"
                    defaultValue={oldremark}
                    onChange={e => setRemark(e.target.value)}
                    className=" block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  ></textarea>
                </div>
                {/* Status Section */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="flex items-center justify-center space-x-5 text-lg w-full">
        <button
          onClick={updateEnquiryform}
          className="flex justify-between py-1 px-2 border-transparent shadow-sm text-lg font-bold rounded-md text-white bg-gray-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 mt-2  border-2 border-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
            />
          </svg>

          <p className="mx-1">Update</p>
        </button>
      </div>

      <div
        className="cursor-pointer absolute top-0 right-0 mt-4 mr-5  border p-1 rounded-md shadow-md border-red-500 bg-red-200 transition duration-100 ease-in-out "
        onClick={props.onClose}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-label="Close"
          className="icon icon-tabler icon-tabler-x"
          width={20}
          height={20}
          viewBox="0 0 24 24"
          strokeWidth="2.5"
          stroke="#dc2626"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <line x1={18} y1={6} x2={6} y2={18} />
          <line x1={6} y1={6} x2={18} y2={18} />
        </svg>
      </div>
    </>
  );
};
export default UpdateEmp;
