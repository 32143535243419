import React, { useState, Fragment, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Main from '../../layout/Main';
// import { ExclamationIcon } from "@heroicons/react/outline";
import AuthContext from '../../store/authContext';
import { ExclamationIcon } from '@heroicons/react/outline';
import { Dialog, Transition } from '@headlessui/react';
// import UserContext from "../../store/userContext";
import ReactLoading from 'react-loading';
import mfaApi from '../../api/mfaApi';
import swal from 'sweetalert';
import UpdateBill from './updatebill';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// import { Dialog, Transition } from "@headlessui/react";
const Paymenthistory = () => {
  const authCtx = useContext(AuthContext);
  const [notifydata, setNotifydata] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    getEnquiry();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [t_refund, setT_refund] = useState(0.0);
  const [t_paid, setT_paid] = useState(0.0);
  const [t_available, setT_available] = useState(0.0);
  const [total, setTotal] = useState(0.0);
  const getEnquiry = async () => {
    try {
      setisLoading(true);
      const response = await mfaApi.get(`/urbanadmin/getpaymenthistory`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authCtx.token}`,
        },
      });
      if (response.status === 200) {
        setNotifydata(response.data.data);
        setTotal(response.data.totalAmount);
        setT_paid(response.data.paidTotal);
        setT_refund(response.data.refundTotal);
        setT_available(response.data.availableBalance);
        setisLoading(false);
      }
    } catch (error) {
      console.log(error);
      setisLoading(false);
      swal(error.response.data.message);
    }
  };
  const [id, setid] = useState();
  //delete model
  const [resenquiry, setResEnquiry] = useState({});
  console.log(resenquiry);

  let [isUpdateHomeOpen, setIsUpdateHomeOpen] = useState(false);
  function closeUpdateHomeModal() {
    setid();
    setResEnquiry({});
    setIsUpdateHomeOpen(false);
  }
  function openUpdateHomeModal(id, status, name, paymentid, createdAt, remark) {
    setid(id); // Assuming setid updates some other state
    console.log(id, status, name, paymentid, createdAt, remark);

    // Update resenquiry with the new values
    setResEnquiry(prevState => ({
      ...prevState, // Spread the previous state in case you want to keep existing properties
      // Or just use id shorthand like `id` if keys and values are the same
      id: id,
      status: status,
      name: name,
      paymentid: paymentid,
      createdAt: createdAt,
      remark: remark,
    }));

    setIsUpdateHomeOpen(true); // Open the modal
  }
  return (
    <Main>
      {' '}
      <div>
        {/* {isLoading && (
          <div className="flex items-center justify-center my-2">
            <ReactLoading
              type="bubbles"
              color="#0143E2"
              height={'4%'}
              width={'4%'}
            />
            Loading...
          </div>
        )} */}
        <section className="mx-auto w-full p-4">
          <h1 className="text-lg font-semibold">Payment History</h1>
          <div className="flex justify-evenly items-center text-black rounded-lg shadow-lg p-3">
            <button className="text-lg font-sans bg-indigo-100 px-2 rounded-md shadow-2xl">
              Total Transaction Amount: {total}
            </button>
            <button className="text-lg font-sans bg-orange-200 px-2 rounded-md shadow-2xl">
              Total Paid: {t_paid}
            </button>
            <button className="text-lg font-sans bg-red-200 px-2 rounded-md shadow-2xl">
              Total Refund: {t_refund}
            </button>
            <button className="text-lg font-sans bg-green-200 px-2 rounded-md shadow-2xl">
              Total Available: {t_available}
            </button>
            <button className="flex items-center bg-blue-700 hover:bg-blue-800 transition duration-300 text-white px-4 py-2 rounded-lg text-sm font-medium shadow-md hover:shadow-xl">
              <a
                href="https://www.newurbanservices.in/paymentgateway"
                target="_blank"
                rel="noreferrer"
                className="flex items-center space-x-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 8.25H9m6 3H9m3 6-3-3h1.5a3 3 0 1 0 0-6M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>

                <p>Online Pay</p>
              </a>
            </button>
          </div>
        </section>
      </div>
      {/* Show Skeleton if data has not yet been loaded */}
      {notifydata.length === 0 ? (
        <div className="my-2">
          <Skeleton count={10} height={30} />{' '}
          {/* Simple skeleton loading with 5 rows */}
        </div>
      ) : (
        <div className="flex flex-col">
          <div className=" overflow-x-auto relative">
            <div className="inline-block w-full">
              <div className="table-wrp block max-h-full lg:max-h-[28rem] ">
                <table className="w-full">
                  <thead className="bg-gray-300 border-b sticky top-0">
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        S.no
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        update
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Date of payment
                      </th>
                      <th
                        scope="col"
                        className="flex justify-end px-2 py-2 whitespace-nowrap text-right text-sm  text-gray-900 uppercase tracking-wider"
                      >
                        Amount
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-center text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-center text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Transaction Id
                      </th>

                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-center text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Remark
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-center text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-center text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Phone No
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Service Type
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Description
                      </th>
                      {/* <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Delete
                      </th> */}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {/* filteredData. */}
                    {notifydata?.map((data, index) => (
                      <tr key={uuidv4()}>
                        <td className="px-2 p-2 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {notifydata.length - index || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap">
                          <button
                            className="flex text-sm font-medium text-gray-900  rounded-lg px-2 py-2"
                            onClick={() =>
                              openUpdateHomeModal(
                                data.id,
                                data.status,
                                data.name,
                                data.paymentid,
                                data.createdAt,
                                data.remark,
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="gray"
                              className="h-5 w-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </button>
                        </td>

                        {/* <td className="px-2 p whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {data.createdAt
                              ? new Date(data.createdAt).toLocaleDateString(
                                  'en-GB',
                                )
                              : 'N/A'}
                          </div>
                        </td> */}
                        <td className="px-2 p whitespace-nowrap">
                          <div className="text-sm text-left font-medium text-gray-900">
                            {data.createdAt
                              ? new Date(data.createdAt).toLocaleDateString(
                                  'en-GB',
                                ) +
                                ' - ' +
                                new Date(data.createdAt).toLocaleTimeString(
                                  'en-GB',
                                  { hour12: true },
                                )
                              : 'N/A'}
                          </div>
                        </td>

                        <td className="px-2 p whitespace-nowrap">
                          <div className="text-sm text-right font-medium text-gray-900">
                            {data.amount || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 pwhitespace-nowrap">
                          <div className="text-sm font-medium text-center text-gray-900">
                            {data.status || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 p whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900 text-center">
                            {data.paymentid || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 pwhitespace-nowrap">
                          <div className="text-sm font-medium text-center text-gray-900">
                            {data.remark || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 p whitespace-nowrap">
                          <div className="text-sm font-medium text-center text-gray-900">
                            {data.name || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 p whitespace-nowrap">
                          <div className="text-sm font-medium text-center text-gray-900">
                            {data.phone || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 p whitespace-normal">
                          <div className="text-sm font-medium text-gray-900">
                            {data.servicename || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 p whitespace-normal break-before-all">
                          <div className="text-sm font-medium text-gray-900 ">
                            {data.description || 'N/A'}
                          </div>
                        </td>
                        {/* <td className="px-4 py-1 whitespace-nowrap">
                          <button
                            // onClick={() => openDeleteModal(data.id)}
                            className="text-base flex  justify-start font-semibold text-red-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      <Transition appear show={isUpdateHomeOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto  "
          onClose={closeUpdateHomeModal}
        >
          <div className="min-h-screen px-4 text-center items-end">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen  align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-100"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full  max-w-4xl  py-2 px-6 my-8 overflow-hidden text-left align-middle mt-32 transition-all transform bg-yellow-50 border-2 border-red-900   shadow-md rounded-xl ">
                <Dialog.Title
                  as="h4"
                  className="text-xl font-bold  text-center my-3 leading-6 text-indigo-900 "
                >
                  Payment Status Update
                </Dialog.Title>
                <UpdateBill
                  enquiryid={id}
                  datares={resenquiry}
                  onClose={closeUpdateHomeModal}
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </Main>
  );
};

export default Paymenthistory;
