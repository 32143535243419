import React, { useContext, useState, useEffect } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import mfaApi from '../../api/mfaApi';
import AuthContext from '../../store/authContext';
import swal from 'sweetalert';
import Main from '../../layout/Main';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; // Import skeleton styles

const Dashboard = () => {
  const authCtx = useContext(AuthContext);
  const [t_refund, setT_refund] = useState(0.0);
  const [t_paid, setT_paid] = useState(0.0);
  const [t_available, setT_available] = useState(0.0);
  const [total, setTotal] = useState(0.0);
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const getEnquiry = async () => {
    try {
      const response = await mfaApi.get(`/urbanadmin/getpaymenthistory`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authCtx.token}`,
        },
      });
      if (response.status === 200) {
        setTotal(response.data.totalAmount);
        setT_paid(response.data.paidTotal);
        setT_refund(response.data.refundTotal);
        setT_available(response.data.availableBalance);
      }
    } catch (error) {
      console.log(error);
      swal(error.response.data.message);
    } finally {
      setIsLoading(false); // Data has finished loading
    }
  };

  useEffect(() => {
    getEnquiry();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Prepare data for the Bar chart
  const barChartData = {
    labels: [
      'Total Amount',
      'Paid Amount',
      'Refund Amount',
      'Available Balance',
    ],
    datasets: [
      {
        label: 'Payment Overview',
        data: [total, t_paid, t_refund, t_available],
        backgroundColor: ['#2196F3', '#9C27B0', '#FF9800', '#4CAF50'],
        borderColor: ['#388E3C', '#1976D2', '#F57C00', '#7B1FA2'],
        borderWidth: 1,
      },
    ],
  };

  const barChartOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  // Prepare data for the Doughnut chart
  const doughnutChartData = {
    labels: ['Paid Amount', 'Refund Amount', 'Available Balance'],
    datasets: [
      {
        label: 'Payment Distribution',
        data: [t_paid, t_refund, t_available],
        backgroundColor: ['#2196F3', '#FF9800', '#4CAF50'],
        hoverBackgroundColor: ['#1976D2', '#F57C00', '#388E3C'],
      },
    ],
  };

  const doughnutChartOptions = {
    cutout: '60%', // Adjust the center cutout to make it more like a ring
  };

  return (
    <Main>
      <div className="flex flex-col w-full md:space-y-2 flex-shrink-0 overflow-scroll scroll-smooth scroll-m-0 bg-gray-50">
        <div className="w-full overflow-hidden">
          <h1 className="flex justify-center text-2xl font-normal text-gray-800 dark:text-white rounded-md">
            Dashboard
          </h1>

          {/* Grid layout for total, paid, refund, and available amounts */}
          <div className="grid grid-cols-2 gap-4 my-1 md:grid-cols-4 lg:grid-cols-4">
            {isLoading ? (
              // Render skeletons while loading
              <>
                <Skeleton height={120} className="rounded-lg" />
                <Skeleton height={120} className="rounded-lg" />
                <Skeleton height={120} className="rounded-lg" />
                <Skeleton height={120} className="rounded-lg" />
              </>
            ) : (
              // Render actual content once loaded
              <>
                <div className="w-full">
                  <div className="relative h-32 w-full px-4 py-1 rounded-lg shadow-lg dark:bg-gray-700 bg-indigo-200">
                    <p className="text-sm text-black border-b border-gray-200 w-max dark:text-white">
                      Total Transanction Amount
                    </p>
                    <div className="flex items-end my-6 space-x-2">
                      <p className="text-3xl font-medium dark:text-white">
                        {total || '0.00'}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="relative h-32 w-full px-4 py-1 rounded-lg shadow-lg dark:bg-gray-700 bg-yellow-100">
                    <p className="text-sm text-black border-b border-gray-200 w-max dark:text-white">
                      Paid Amount
                    </p>
                    <div className="flex items-end my-6 space-x-2">
                      <p className="text-3xl font-medium dark:text-white">
                        {t_paid || '0.00'}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="relative h-32 w-full px-4 py-1 rounded-lg shadow-lg dark:bg-gray-700 bg-red-200">
                    <p className="text-sm text-black border-b border-gray-200 w-max dark:text-white">
                      Refund Amount
                    </p>
                    <div className="flex items-end my-6 space-x-2">
                      <p className="text-3xl font-medium dark:text-white">
                        {t_refund || '0.00'}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="relative h-32 w-full px-4 py-1 rounded-lg shadow-lg dark:bg-gray-700 bg-green-200">
                    <p className="text-sm text-black border-b border-gray-200 w-max dark:text-white">
                      Available Balance
                    </p>
                    <div className="flex items-end my-6 space-x-2">
                      <p className="text-3xl font-medium dark:text-white">
                        {t_available || '0.00'}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Bar Chart representing the data */}
          <div className="flex flex-col md:flex-row justify-evenly">
            {/* Bar Chart */}
            <div className="w-full md:w-1/2 mt-8">
              <h2 className="text-xl text-center font-medium text-gray-800 dark:text-white">
                Payment Summary Chart
              </h2>
              {isLoading ? (
                <Skeleton height={300} />
              ) : (
                <Bar data={barChartData} options={barChartOptions} />
              )}
            </div>

            {/* Doughnut Chart */}
            <div className="w-full md:w-1/3 mt-8">
              <h2 className="text-xl text-center font-medium text-gray-800 dark:text-white">
                Payment Distribution Chart
              </h2>
              {isLoading ? (
                <Skeleton height={300} />
              ) : (
                <Doughnut
                  data={doughnutChartData}
                  options={doughnutChartOptions}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default Dashboard;
