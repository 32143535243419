import React, { useState, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
// import { ExclamationIcon } from "@heroicons/react/outline";
import AuthContext from '../../store/authContext';
// import UserContext from "../../store/userContext";
import ReactLoading from 'react-loading';
import mfaApi from '../../api/mfaApi';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
// import { Dialog, Transition } from "@headlessui/react";
const WorkAssignEnq = props => {
  const navigate = useNavigate();
  function NavigatetoWorkPage() {
    navigate(`/work`, { replace: true });
  }
  let oldresponsedata = props.datares;
  let oldname = oldresponsedata.fullname;
  let oldemail = oldresponsedata.email;
  let oldphone = oldresponsedata.phone;
  let oldservicename = oldresponsedata.servicetype;
  let oldpincode = oldresponsedata.areapincode;
  let olddes = oldresponsedata.description;
  let oldaddress = oldresponsedata.address;
  let olddate = oldresponsedata.servicedate;
  console.log(oldname);
  const authCtx = useContext(AuthContext);
  const [resdata, setResdata] = useState([]);
  const [workerid, setWorkerid] = useState();
  const [emp_id, setEmp_id] = useState('');
  const [servicedate, setServicedate] = useState('');
  const [workhistory, setWorkhistory] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [servicename, setServicename] = useState('');
  const [isLoadingforwworker, setIsLoadingforwworker] = useState(false);

  useEffect(() => {
    if (emp_id && servicedate) {
      getWorkhistory();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emp_id, servicedate]);

  const handleChangeforworker = event => {
    const selectedData = JSON.parse(event.target.value); // Parse JSON string
    const { id, workerempi } = selectedData; // Destructure to get id and workerempi
    console.log('Selected Worker ID:', id);
    console.log('Selected Worker Emp ID:', workerempi);

    // Store values as needed
    setWorkerid(id);
    setEmp_id(workerempi);
    // You can also store workerempi or use it for further actions
  };
  const CreateWorkAssign = async e => {
    e.preventDefault();
    if (servicename === '' && emp_id === '') {
      swal('please enter all column values (non-empty values).');
    } else {
      setisLoading(true);
      try {
        const response = await mfaApi.post(
          `/urbanadmin/createworkassign`,
          {
            worker_id: workerid,
            workerempi: emp_id,
            fullname: oldname,
            email: oldemail,
            phone: oldphone,
            servicetype: servicename,
            areapincode: oldpincode,
            servicedate: servicedate,
            address: oldaddress,
            workdetails: olddes,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authCtx.token}`,
            },
          },
        );
        if (response.status === 201) {
          // await window.location.reload();
          NavigatetoWorkPage();
          setisLoading(false);
          swal(response.data.message);
        }
      } catch (error) {
        swal(error.response.data.message);
        setisLoading(false);
      }
      setisLoading(false);
    }
  };

  const getWorkhistory = async () => {
    try {
      setisLoading(true);
      const response = await mfaApi.post(
        `/urbanadmin/workerworkfind`,
        {
          servicedate: servicedate,
          emp_id: workerid,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authCtx.token}`,
          },
        },
      );
      if (response.status === 200) {
        setWorkhistory(response.data.data);
        setisLoading(false);
      }
    } catch (error) {
      console.log(error);
      setisLoading(false);
      swal(error.response.data.message);
    }
  };
  const [workerhis, setWrokerhis] = useState([]);
  const findWorkerlist = async () => {
    try {
      setIsLoadingforwworker(true);
      const response = await mfaApi.get(
        `/urbanadmin/getempbyservicetype/${servicename}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authCtx.token}`,
          },
        },
      );
      if (response.status === 200) {
        setIsLoadingforwworker(false);
        setWrokerhis(response.data.data);
      } else {
        swal(response.data.message);
        setIsLoadingforwworker(false);
        setWrokerhis([]);
      }
    } catch (error) {
      swal(error.response.data.message);
      setIsLoadingforwworker(false);
      setWrokerhis([]);
    }
  };
  useEffect(() => {
    if (servicename) {
      // Only run if servicename exists
      findWorkerlist();
    }
    // You can perform cleanup by returning a function
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicename]); // Run only when servicename changes and is not empty

  //delete model

  const getServicesDetails = async () => {
    try {
      const response = await mfaApi.get(`/urbanadmin/getservicepublic`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setResdata(response.data.data);
        // setTotal(response.data);
      } else {
        swal(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    // Place your side effect code here
    // This code will run after the component renders
    getServicesDetails();
    // You can perform cleanup by returning a function
    return () => {};
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, []);
  const handleChange = event => {
    const selectedValue = event.target.value;
    setServicename(selectedValue);
  };

  return (
    <>
      {' '}
      <div>
        <section className="mx-auto  w-full overflow-hidden">
          <div className="shadow  bg-yellow-50 overflow-hidden sm:rounded-md py-2">
            <div className="px-4 bg-yellow-50">
              <div className="grid grid-cols-1 gap-2  md:grid-cols-3 md:gap-6">
                <div>
                  <label
                    htmlFor="notificationmsg"
                    className="block text-sm font-semibold text-gray-700"
                  >
                    Full Name
                  </label>
                  <p className="text-gray-700 font-sans p-1  border-gray-300 bg-gray-200 rounded-md">
                    {oldname}
                  </p>
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-semibold text-gray-700"
                  >
                    Phone
                  </label>
                  <p className="text-gray-700 font-sans p-1  border-gray-300 bg-gray-200 rounded-md">
                    {oldphone}
                  </p>
                </div>
                <div>
                  <label
                    htmlFor="notificationmsg"
                    className="block text-sm font-semibold  text-gray-700"
                  >
                    Email
                  </label>
                  <p className="text-gray-700 font-sans p-1  border-gray-300 bg-gray-200 rounded-md">
                    {oldemail}
                  </p>
                </div>

                <div>
                  <label
                    htmlFor="notificationmsg"
                    className="block text-sm font-semibold  text-gray-700"
                  >
                    Work Details
                  </label>
                  <p className="text-gray-700 font-sans p-1  border-gray-300 bg-gray-200 rounded-md">
                    {olddes}
                  </p>
                </div>
                <div>
                  <label
                    htmlFor="notificationmsg"
                    className="block text-sm font-semibold  text-gray-700"
                  >
                    Pincode
                  </label>
                  <p className="text-gray-700 font-sans p-1  border-gray-300 bg-gray-200 rounded-md">
                    {oldpincode}
                  </p>
                </div>
                <div>
                  <label
                    htmlFor="notificationmsg"
                    className="block text-sm font-semibold  text-gray-700"
                  >
                    Address
                  </label>
                  <p className="text-gray-700 font-sans p-1  border-gray-300 bg-gray-200 rounded-md break-all">
                    {oldaddress}
                  </p>
                </div>
                <div>
                  <label className="flex text-green-700 text-sm font-sans">
                    <p className="text-black px-1 font-semibold"> Pre Value:</p>{' '}
                    {oldservicename}
                  </label>
                  <div className="relative flex items-center">
                    <select
                      name="serviceType"
                      required
                      className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      defaultValue=""
                      onChange={handleChange} // Attach handler
                    >
                      <option value="" disabled hidden>
                        Select service type
                      </option>
                      {resdata.map(service => (
                        <option key={service.id} value={service.servicename}>
                          {service.servicename}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <label className="flex text-gray-800 text-xs font-semibold">
                    Worker for
                    <span className="flex px-2 text-orange-600 text-sm font-semibold">
                      {servicename}{' '}
                    </span>{' '}
                  </label>

                  <div className="relative flex items-center">
                    <select
                      name="serviceType"
                      required
                      className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md py-2"
                      defaultValue=""
                      onChange={handleChangeforworker} // Attach handler
                    >
                      <option value="" disabled hidden>
                        Select Worker
                      </option>
                      {workerhis
                        .filter(worker => worker.status === 'Active') // Filter only 'Active' workers
                        .map(service => (
                          <option
                            key={service.id}
                            value={JSON.stringify({
                              id: service.id,
                              workerempi: service.workerempi,
                            })} // Store both id and workerempi
                          >
                            {service.fullname} - {service.workerempi}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="notificationmsg"
                    className="flex  text-sm font-semibold text-gray-700"
                  >
                    Assign Service Date:
                  </label>
                  <input
                    type="date"
                    name="notificationmsg"
                    id="notificationmsg"
                    value={servicedate}
                    onChange={e => setServicedate(e.target.value)}
                    className=" block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  ></input>
                  <p className="flex text-yellow-700 px-1">
                    Enquiry Date {new Date(olddate).toLocaleDateString('en-GB')}
                  </p>
                </div>
              </div>
              <div className="flex justify-end py-2 px-2">
                <button
                  onClick={CreateWorkAssign}
                  className="inline-flex justify-center py-1 px-2 border border-transparent shadow-sm text-lg font-bold rounded-md text-white bg-gray-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </section>
        <div
          className="cursor-pointer absolute top-0 right-0 mt-4 mr-5  border p-1 rounded-md shadow-md border-red-500 bg-red-200 transition duration-100 ease-in-out "
          onClick={props.onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-label="Close"
            className="icon icon-tabler icon-tabler-x"
            width={20}
            height={20}
            viewBox="0 0 24 24"
            strokeWidth="2.5"
            stroke="#dc2626"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <line x1={18} y1={6} x2={6} y2={18} />
            <line x1={6} y1={6} x2={18} y2={18} />
          </svg>
        </div>
      </div>
      {isLoading && (
        <div className="flex items-center justify-center my-5">
          <ReactLoading
            type="bubbles"
            color="#0143E2"
            height={'4%'}
            width={'4%'}
          />
          Loading...
        </div>
      )}
      {isLoadingforwworker && (
        <div className="flex items-center justify-center my-2 px-2">
          <ReactLoading
            type="spinningBubbles"
            color="blue"
            height={'2%'}
            width={'2%'}
          />
          Loading Worker...
        </div>
      )}
      {workhistory.length > 0 && (
        <div className="flex flex-col">
          <div className=" overflow-x-auto relative">
            <div className="inline-block w-full">
              <div className="table-wrp block max-h-full lg:max-h-[28rem]">
                <table className="w-full">
                  <thead className="bg-gray-300 border-b sticky top-0">
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        S.no
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Worker_Id
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Work Status
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Service Date
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Service Type
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Customer Name
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Phone No
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Pinocde
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Address
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Added By
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {/* filteredData. */}
                    {workhistory?.map((data, index) => (
                      <tr key={uuidv4()}>
                        <td className="px-2 py-1 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {workhistory.length - index || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2  whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {data.workerempi || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap">
                          <div
                            className={`text-sm font-semibold ${
                              data.empworkstatus === 'In Progress'
                                ? 'text-red-600 animate-pulse'
                                : 'text-green-600'
                            }`}
                          >
                            {data.empworkstatus || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {data.servicedate
                              ? new Date(data.servicedate).toLocaleDateString(
                                  'en-GB',
                                )
                              : 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {data.servicetype || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {data.workdetails || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 py-1  whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {data.fullname || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {data.phone || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {data.email || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {data.areapincode || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap break-before-auto">
                          <div className="text-sm font-medium text-gray-900">
                            {data.address || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap break-before-auto">
                          <div className="text-sm font-medium text-gray-900">
                            {data.addedby || 'N/A'}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WorkAssignEnq;
