import Dashboard from '../pages/Dashboard/Dashboard';
import TechSupport from '../pages/support/techsupport';
//import NotFound from '../pages/NotFound';
import EnquiryForm from '../pages/Enquiry/CreateEnquiry';
import Services from '../pages/Services/CreateService';
import Employe from '../pages/Employe/Employe';
import WorkAssign from '../pages/WorkAssign/Work';
import Paymenthistory from '../pages/Invoice/billGen';
// iocns
import {
  BellIcon,
  BriefcaseIcon,
  DesktopComputerIcon,
  ServerIcon,
  SupportIcon,
  UserAddIcon,
  UserGroupIcon,
} from '@heroicons/react/solid';

//admin routs
export const BankRoutes = [
  {
    name: 'Dashboard',
    to: '/dashboard',
    element: <Dashboard />,
    icons: <DesktopComputerIcon className="h-6 text-gray-500 font-sans" />,
  },
  {
    name: 'Enquiry',
    to: '/enquiryform',
    element: <EnquiryForm />,
    icons: <BellIcon className="h-6 text-gray-500 font-sans" />,
  },
  {
    name: 'Work Assign',
    to: '/work',
    element: <WorkAssign />,
    icons: <BriefcaseIcon className="h-6 text-gray-500 font-sans" />,
  },

  {
    name: 'Payement History',
    to: '/paymenthistory',
    element: <Paymenthistory />,
    icons: <UserGroupIcon className="h-6 text-gray-500 font-sans" />,
  },
  {
    name: 'Employee',
    to: '/employee',
    element: <Employe />,
    icons: <UserAddIcon className="h-6 text-gray-500 font-sans" />,
  },
  {
    name: 'Service',
    to: '/service',
    element: <Services />,
    icons: <ServerIcon className="h-6 text-gray-500 font-sans" />,
  },
  // {
  //   name: 'Tech Support',
  //   to: '/techsupport',
  //   element: <TechSupport />,
  //   icons: <SupportIcon className="h-6 text-gray-500 font-sans" />,
  // },
];
export const BranchRoutes = [
  // {
  //   name: "Bank Journel Entry",
  //   to: "/bjentry",
  //   element: <Bankjournel />,
  //   icons: <CashIcon className="h-6 text-gray-500 font-sans" />,
  // },
  // {
  //   name: "Support",
  //   to: "/support",
  //   element: <TechSupport />,
  //   icons: <SupportIcon className="h-6 text-gray-500 font-sans" />,
  // },
];

export const YasystemRoutes = [
  // {
  //   name: "Dashboard",
  //   to: "/dashboard",
  //   element: <Dashboard />,
  //   icons: <DesktopComputerIcon className="h-6 text-gray-500 font-sans" />,
  // },
  // {
  //   name: "Support",
  //   to: "/support",
  //   element: <TechSupport />,
  //   icons: <SupportIcon className="h-6 text-gray-500 font-sans" />,
  // },
];
