import React, { useState, Fragment, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Main from '../../layout/Main';
// import { ExclamationIcon } from "@heroicons/react/outline";
import AuthContext from '../../store/authContext';
import { ExclamationIcon } from '@heroicons/react/outline';
import { Dialog, Transition } from '@headlessui/react';
// import UserContext from "../../store/userContext";
import ReactLoading from 'react-loading';
import mfaApi from '../../api/mfaApi';
import swal from 'sweetalert';
import UpdateEnquiry from './updateEnquiry';
import WorkAssignEnq from './workAssign';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
// import { Dialog, Transition } from "@headlessui/react";
const EnquiryForm = () => {
  const authCtx = useContext(AuthContext);
  const [id, setid] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [pinocde, setPincode] = useState('');
  const [address, setAddress] = useState('');
  const [description, setDescription] = useState('');
  const [servicedate, setServicedate] = useState('');
  const [notifydata, setNotifydata] = useState([]);
  const [added, setadded] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isLoadingdelete, setisLoadingdelete] = useState(false);
  const [searchvalue, setSearchvalue] = useState('');
  console.log(searchvalue);
  const [issearchLoading, setIssearchLoading] = useState(false);
  const [error, setError] = useState('');
  const [errorpin, setErrorpin] = useState('');

  const handlePhoneChange = e => {
    const value = e.target.value;

    // Allow only digits and ensure the input does not exceed 10 digits
    if (/^\d*$/.test(value) && value.length <= 10) {
      setPhone(value);

      // Clear the error if input is valid
      if (value.length === 10) {
        setError('');
      } else {
        setError('');
      }
    } else if (value.length > 10) {
      setError('Phone number cannot exceed 10 digits');
    }
  };

  const handlePincode = e => {
    const value = e.target.value;

    // Allow only digits and ensure the input does not exceed 10 digits
    if (/^\d*$/.test(value) && value.length <= 6) {
      setPincode(value);

      // Clear the error if input is valid
      if (value.length === 6) {
        setErrorpin('');
      } else {
        setErrorpin('');
      }
    } else if (value.length > 6) {
      setErrorpin('Pincode cannot exceed 6 digits');
    }
  };
  useEffect(() => {
    getEnquiry();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [added]);

  const addNotification = async e => {
    e.preventDefault();
    if (name === '' && pinocde === '' && phone === '' && servicename === '') {
      swal('please enter all column values (non-empty values).');
    } else {
      setisLoading(true);
      try {
        const response = await mfaApi.post(
          `/urbanadmin/enquiry-form`,
          {
            fullname: name,
            email: email,
            phone: phone,
            servicetype: servicename,
            areapincode: pinocde,
            servicedate: servicedate,
            address: address,
            description: description,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authCtx.token}`,
            },
          },
        );
        if (response.status === 201) {
          setisLoading(false);
          swal(response.data.message);
          await window.location.reload();
        }
      } catch (error) {
        swal(error.response.data.message);
        setisLoading(false);
      }
      setisLoading(false);
    }
  };

  const getEnquiry = async () => {
    try {
      const response = await mfaApi.get(`/urbanadmin/get_enquiry_form`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authCtx.token}`,
        },
      });
      if (response.status === 200) {
        setNotifydata(response.data.data);
      }
    } catch (error) {
      console.log(error);

      swal(error.response.data.message);
    }
  };

  //delete model
  let [isDeleteOpen, setIsDeleteOpen] = useState(false);

  function closeDeleteModal() {
    setIsDeleteOpen(false);
  }

  function openDeleteModal(id) {
    setIsDeleteOpen(true);
    setid(id);
  }
  const deleteHandler = async () => {
    try {
      setisLoadingdelete(true);
      const response = await mfaApi.delete(`/urbanadmin/enquirydelete/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authCtx.token}`,
        },
      });
      if (response.status === 201) {
        swal('Delete Successfully');
        setadded(true);
        await getEnquiry();
        setisLoadingdelete(false);
      } else {
        swal(response.data.message);
        setisLoadingdelete(false);
      }
    } catch (error) {
      swal(error.response.data.message);
      setisLoadingdelete(false);
    }
    setIsDeleteOpen(false);
  };

  let [isUpdateHomeOpen, setIsUpdateHomeOpen] = useState(false);
  function closeUpdateHomeModal() {
    setid();
    setResEnquiry({});
    setIsUpdateHomeOpen(false);
  }
  function openUpdateHomeModal(id) {
    setid(id);
    setIsUpdateHomeOpen(true);
  }
  let [isWorkassignOpen, setIsWorkassignOpen] = useState(false);
  function closeWorkassignOpen() {
    setIsWorkassignOpen(false);
  }
  function WorkAssignFunction(
    fullname,
    email,
    id,
    phone,
    servicetype,
    servicedate,
    address,
    description,
  ) {
    setid(id);
    setIsWorkassignOpen(true);
    console.log(
      fullname,
      email,
      id,
      phone,
      servicetype,
      servicedate,
      address,
      description,
    );
  }

  const [resdata, setResdata] = useState([]);

  const getServicesDetails = async () => {
    try {
      const response = await mfaApi.get(`/urbanadmin/getservicepublic`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setResdata(response.data.data);
        // setTotal(response.data);
      } else {
        swal(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  useEffect(() => {
    getServicesDetails();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [servicename, setServicename] = useState('');
  const handleChange = event => {
    const selectedValue = event.target.value;
    setServicename(selectedValue);
  };
  const [resenquiry, setResEnquiry] = useState({});
  const getEnquirybyId = async () => {
    try {
      const response = await mfaApi.get(`/urbanadmin/getenquirybyid/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authCtx.token}`,
        },
      });

      if (response.status === 200) {
        setResEnquiry(response.data.data);
        // setTotal(response.data);
      } else {
        swal(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  useEffect(() => {
    // Check if id has a value before calling getEnquirybyId
    if (id) {
      getEnquirybyId();
    }

    // Cleanup function (if needed)
    return () => {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const gettreasurytabledatabysearch = event => {
    setSearchvalue(event.target.value);
    // Additional logic or function calls if needed
  };
  useEffect(() => {
    // Place your side effect code here
    // This code will run after the component renders
    getEnquiry();
    // You can perform cleanup by returning a function
    return () => {};
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, [searchvalue === '']);
  function reloadPageWithDelay() {
    setTimeout(function () {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }, 2000); // 2000 milliseconds (2 seconds)
  }
  const gettreasurytabledatabysearchapi = async () => {
    try {
      setIssearchLoading(true);
      const response = await mfaApi.get(
        `/urbanadmin/searchenquiry/${searchvalue}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authCtx.token}`,
          },
        },
      );
      if (response.status === 200) {
        setNotifydata(response.data.data);
        // setTotal(response.data);
        setIssearchLoading(false);
      } else {
        swal(response.data.message);
        setIssearchLoading(false);
        reloadPageWithDelay();
      }
    } catch (error) {
      swal(error.response.data.message);
      setIssearchLoading(false);
      reloadPageWithDelay();
    }
  };
  return (
    <Main>
      {' '}
      <div>
        <section className="mx-auto  w-full">
          <div className="bg-gray-100 rounded-md shadow-2xl shadow-green-600 py-1 mb-1">
            <p className="mx-auto flex justify-center uppercase text-black lg:text-2xl md:text-xl sm:text-lg font-semibold ml-2 text-center">
              {' '}
              Equiry Leads
            </p>
            {issearchLoading && (
              <div className="flex items-center justify-end ">
                <ReactLoading
                  type="spinningBubbles"
                  color="#00000"
                  height={'2%'}
                  width={'2%'}
                />
                <p className="flex px-1 font-medium text-black">
                  please wait...
                </p>
              </div>
            )}
          </div>
          <span className="flex justify-end text-white ">
            <input
              type="text"
              value={searchvalue}
              onChange={gettreasurytabledatabysearch}
              placeholder="Search....."
              className="text-left rounded-md text-sm text-black flex justify-end mr-2 w-1/4"
            />
            <div className="flex justify-end ">
              <button
                onClick={gettreasurytabledatabysearchapi}
                className="inline-flex justify-center py-1 px-2 border border-transparent shadow-sm text-lg font-bold rounded-md text-white bg-gray-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                  />
                </svg>
                <p className="mx-1">Search</p>
              </button>
            </div>
          </span>
        </section>
      </div>
      <div>
        <section className="mx-auto  w-full ">
          <div className="shadow  bg-yellow-100 overflow-hidden sm:rounded-md">
            <div className="px-4 bg-yellow-100 py-2">
              <div className="grid grid-cols-2 gap-2  md:grid-cols-4 md:gap-6">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    className=" block w-full shadow-sm sm:text-sm border-gray-300 rounded-md "
                    required
                  ></input>
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone
                  </label>
                  <input
                    type="text" // Use text to control input length and format
                    name="phone"
                    id="phone"
                    value={phone}
                    onChange={handlePhoneChange}
                    className={`block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                      error ? 'border-red-500' : ''
                    }`}
                    required
                  />
                  {error && (
                    <p className="text-red-500 text-sm mt-1">{error}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium  text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    className=" block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  ></input>
                </div>

                <div>
                  <label className="text-gray-800 text-xs block ">
                    Service Type
                  </label>
                  <div className="relative flex items-center">
                    <select
                      name="serviceType"
                      required
                      className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      defaultValue=""
                      onChange={handleChange} // Attach handler
                    >
                      <option value="" disabled hidden>
                        Select service type
                      </option>
                      {resdata.map(service => (
                        <option key={service.id} value={service.servicename}>
                          {service.servicename}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="date"
                    className="block text-sm font-medium  text-gray-700"
                  >
                    Service Date
                  </label>
                  <input
                    type="date"
                    name="date"
                    id="date"
                    value={servicedate}
                    onChange={e => setServicedate(e.target.value)}
                    className=" block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  ></input>
                </div>

                <div>
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium  text-gray-700"
                  >
                    Description
                  </label>
                  <input
                    type="text"
                    name="description"
                    id="description"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    className=" block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  ></input>
                </div>
                <div>
                  <label
                    htmlFor="pincode"
                    className="block text-sm font-medium  text-gray-700"
                  >
                    Pincode
                  </label>
                  <input
                    type="number"
                    name="notificationmsg"
                    id="notificationmsg"
                    value={pinocde}
                    onChange={handlePincode}
                    className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  />
                  {errorpin && (
                    <p className="text-red-500 text-xs mt-1">{errorpin}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium  text-gray-700"
                  >
                    Address
                  </label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                    className=" block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  ></input>
                </div>
              </div>
            </div>
            <div className="flex justify-end py-2 px-6">
              <button
                onClick={addNotification}
                className="inline-flex justify-center py-1 px-2 border border-transparent shadow-sm text-lg font-bold rounded-md text-white bg-gray-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Submit
              </button>
            </div>
          </div>
        </section>
      </div>
      {isLoading && (
        <div className="flex items-center justify-center my-2">
          <ReactLoading
            type="bubbles"
            color="#0143E2"
            height={'4%'}
            width={'4%'}
          />
          Loading...
        </div>
      )}
      {isLoadingdelete && (
        <div className="flex items-center justify-end my-10">
          <ReactLoading
            type="bubbles"
            color="#0143E2"
            height={'4%'}
            width={'4%'}
          />
          Deleting please wait....
        </div>
      )}
      {/* Show Skeleton if data has not yet been loaded */}
      {notifydata.length === 0 ? (
        <div className="my-2">
          <Skeleton count={10} height={30} />{' '}
          {/* Simple skeleton loading with 5 rows */}
        </div>
      ) : (
        <div className="flex flex-col">
          <div className=" overflow-x-auto relative">
            <div className="inline-block w-full">
              <div className="table-wrp block max-h-full lg:max-h-[28rem]">
                <table className="w-full">
                  <thead className="bg-gray-300 border-b sticky top-0">
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        S.no
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-center text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Work Assign
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        View / update
                      </th>

                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Date Of Enquiry
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Service Type
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Enquiry Desc.
                      </th>

                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Customer Name
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Phone No
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Pinocde
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Address
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {/* filteredData. */}
                    {notifydata?.reverse().map((data, index) => (
                      <tr key={uuidv4()}>
                        <td className="px-2 p whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {notifydata.length - index || 'N/A'}
                          </div>
                        </td>
                        <td className="px-4 py-1 whitespace-nowrap">
                          <div
                            className="overflow-auto text-sm font-medium justify-center mt-1  text-gray-900 rounded-xl p px-2"
                            onClick={() =>
                              WorkAssignFunction(
                                data.fullname,
                                data.email,
                                data.id,
                                data.phone,
                                data.servicetype,
                                data.servicedate,
                                data.address,
                                data.description,
                              )
                            }
                          >
                            <p className="flex justify-center overflow-hidden">
                              {' '}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="size-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
                                />
                              </svg>
                            </p>
                          </div>
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap">
                          <button
                            className="flex text-sm font-medium text-gray-900  rounded-lg px-2 py-2"
                            onClick={() => openUpdateHomeModal(data.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="green"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="gray"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </button>
                        </td>

                        <td className="px-2 p whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {data.servicedate
                              ? new Date(data.servicedate).toLocaleDateString(
                                  'en-GB',
                                )
                              : 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 p whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {data.servicetype || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 break break-after-all">
                          <div
                            className="text-sm font-medium text-gray-900 break-words w-full"
                            style={{ wordWrap: 'break-word', width: '40ch' }}
                          >
                            {data.description || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 p whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {data.fullname || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 p whitespace-nowrap">
                          <div className="flex text-md font-medium text-gray-900">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="h-5 w-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                              />
                            </svg>
                            ..
                            <a
                              href={`tel:${data.phone}`}
                              className="text-blue-800 font-medium hover:underline px-1"
                            >
                              {data.phone || 'N/A'}
                            </a>
                          </div>
                        </td>
                        <td className="px-2 pwhitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {data.email || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 p whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {data.areapincode || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 break break-after-all">
                          <div
                            className="text-sm font-medium text-gray-900 break-words w-full"
                            style={{ wordWrap: 'break-word', width: '40ch' }}
                          >
                            {data.address || 'N/A'}
                          </div>
                        </td>
                        <td className="px-4 py-1 whitespace-nowrap">
                          <button
                            onClick={() => openDeleteModal(data.id)}
                            className="text-base flex  justify-start font-semibold text-red-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      <Transition appear show={isUpdateHomeOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto  "
          onClose={closeUpdateHomeModal}
        >
          <div className="min-h-screen px-4 text-center items-end">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen  align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-100"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full  max-w-4xl  py-2 px-6 my-8 overflow-hidden text-left align-middle mt-32 transition-all transform bg-yellow-50 border-2 border-red-900   shadow-md rounded-xl ">
                <Dialog.Title
                  as="h3"
                  className="text-xl font-bold  text-center my-3 leading-6 text-indigo-900 "
                >
                  View / Update
                  <h4 className="text-black"> Enquiry Form</h4>
                </Dialog.Title>
                <UpdateEnquiry
                  enquiryid={id}
                  datares={resenquiry}
                  onClose={closeUpdateHomeModal}
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isWorkassignOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto  "
          onClose={closeWorkassignOpen}
        >
          <div className="min-h-screen px-4 text-center items-end">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen  align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-100"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full  max-w-8xl  py-2 px-6 my-6 overflow-hidden text-left align-top mt-20 transition-all transform bg-white border-2 border-red-900   shadow-md rounded-xl ">
                <Dialog.Title
                  as="h3"
                  className="text-xl font-bold  text-center my-3 leading-6 text-indigo-900 "
                >
                  Work History / Worker Assign
                </Dialog.Title>
                <WorkAssignEnq
                  enquiryid={id}
                  datares={resenquiry}
                  onClose={closeWorkassignOpen}
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isDeleteOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto "
          onClose={closeDeleteModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen  align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-md transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Delete
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-900">
                          Are you sure you want to delete ? All of your data
                          will be permanently removed. This action cannot be
                          undone.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={deleteHandler}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={closeDeleteModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </Main>
  );
};

export default EnquiryForm;
