import React, { useState, useEffect, useCallback } from 'react';

let SignoutTimer;

const AuthContext = React.createContext({
  token: '',
  // refreshToken: "",
  isAuthenticated: false,
  Signin: () => {},
  Signout: () => {},
});

const calculateRemainingTime = expirationTime => {
  const currentTime = new Date().getTime();
  const adjustedExpirationTime = new Date(expirationTime).getTime();
  return adjustedExpirationTime - currentTime;
};

const retrieveStoredToken = () => {
  const storedToken = sessionStorage.getItem('token');
  // const storedRefreshToken = sessionStorage.getItem("refreshToken");
  const storedExpirationTime = sessionStorage.getItem('expirationTime');
  const remainingTime = calculateRemainingTime(storedExpirationTime);

  if (remainingTime <= 3600) {
    sessionStorage.removeItem('token');
    // sessionStorage.removeItem("refreshToken");
    sessionStorage.removeItem('expirationTime');
    return null;
  }
  return {
    token: storedToken,
    // refreshToken: storedRefreshToken,
    duration: remainingTime,
  };
};

export const AuthContextProvider = props => {
  const tokenData = retrieveStoredToken();
  let initialToken;
  // let initialRefreshToken;
  if (tokenData) {
    initialToken = tokenData.token;
    // initialRefreshToken = tokenData.refreshToken;
  }

  const [token, setToken] = useState(initialToken);
  // const [refreshToken, setRefreshToken] = useState(initialRefreshToken);
  const userIsAuthenticated = !!token;

  const SignoutHandler = useCallback(() => {
    setToken(null);
    // setRefreshToken(null);
    sessionStorage.clear();
    if (SignoutTimer) {
      clearTimeout(SignoutTimer);
    }
  }, []);

  const SigninHandler = (token, expirationTime) => {
    setToken(token);
    // setRefreshToken(refreshToken);

    sessionStorage.setItem('token', token);
    // sessionStorage.setItem("refreshToken", refreshToken);
    sessionStorage.setItem('expirationTime', expirationTime);

    const remainingTime = calculateRemainingTime(expirationTime);
    SignoutTimer = setTimeout(SignoutHandler, remainingTime);
  };

  useEffect(() => {
    if (tokenData) {
      SignoutTimer = setTimeout(SignoutHandler, tokenData.duration);
    }
  }, [tokenData, SignoutHandler]);

  const contextValue = {
    token: token,
    // refreshToken: refreshToken,
    isAuthenticated: userIsAuthenticated,

    Signin: SigninHandler,
    Signout: SignoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
