import React, { useState, Fragment, useContext, useEffect } from 'react';
import mfaApi from '../../api/mfaApi';
import AuthContext from '../../store/authContext';
import swal from 'sweetalert';
import ReactLoading from 'react-loading';
const UpdateEnquiry = props => {
  let id = props.enquiryid;
  let oldresponsedata = props.datares;
  let oldname = oldresponsedata.fullname;
  let oldemail = oldresponsedata.email;
  let oldphone = oldresponsedata.phone;
  let oldservicename = oldresponsedata.servicetype;
  let oldpincode = oldresponsedata.areapincode;
  let olddes = oldresponsedata.description;
  let oldaddress = oldresponsedata.address;
  let olddate = oldresponsedata.servicedate;
  console.log(oldname);

  // Set the default value when the component mounts
  const authCtx = useContext(AuthContext);
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [description, setDescription] = useState('');
  const [servicedate, setServicedate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const [isLoadingdelete, setisLoadingdelete] = useState(false);
  const [error, setError] = useState('');
  const [errorpin, setErrorpin] = useState('');
  const [servicename, setServicename] = useState('');
  const [pincode, setPincode] = useState('');
  const handlePhoneChange = e => {
    const value = e.target.value;

    // Allow only digits and ensure the input does not exceed 10 digits
    if (/^\d*$/.test(value) && value.length <= 10) {
      setPhone(value);

      // Clear the error if input is valid
      if (value.length === 10) {
        setError('');
      } else {
        setError('');
      }
    } else if (value.length > 10) {
      setError('Phone number cannot exceed 10 digits');
    }
  };

  const handlePincode = e => {
    const value = e.target.value;

    // Allow only digits and ensure the input does not exceed 10 digits
    if (/^\d*$/.test(value) && value.length <= 6) {
      setPincode(value);

      // Clear the error if input is valid
      if (value.length === 6) {
        setErrorpin('');
      } else {
        setErrorpin('');
      }
    } else if (value.length > 6) {
      setErrorpin('Pincode cannot exceed 6 digits');
    }
  };

  useEffect(() => {
    // Place your side effect code here
    // This code will run after the component renders
    getServicesDetails();
    // You can perform cleanup by returning a function
    return () => {};
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, []);

  const handleChange = event => {
    const selectedValue = event.target.value;
    setServicename(selectedValue);
  };

  function reloadPageWithDelay() {
    setTimeout(function () {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }, 2000); // 2000 milliseconds (2 seconds)
  }

  // EJ FILE UPLOAD API

  const updateEnquiryform = async e => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const response = await mfaApi.put(
        `/urbanadmin/updateenquiryform/${id}`,
        {
          fullname: name,
          email: email,
          phone: phone,
          servicetype: servicename,
          areapincode: pincode,
          servicedate: servicedate,
          description: description,
          address: address,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authCtx.token}`,
          },
        },
      );
      if (response.status === 201) {
        swal(response.data.message);
        setIsLoading(false);
        reloadPageWithDelay();
      }
    } catch (error) {
      swal({
        title: '🤦‍♂️',
        text: error.response.data.message,
        type: 'error',
        confirmButtonText: 'Cool',
      });
    }
    setIsLoading(false);
  };

  const [resdata, setResdata] = useState([]);
  const getServicesDetails = async () => {
    try {
      const response = await mfaApi.get(`/urbanadmin/getservicepublic`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setResdata(response.data.data);
        // setTotal(response.data);
      } else {
        swal(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  return (
    <>
      <section className="mx-auto  w-full overflow-hidden">
        {isLoading && (
          <div className="flex items-center justify-center my-5">
            <ReactLoading
              type="spinningBubbles"
              color="#0143E2"
              height={'6%'}
              width={'6%'}
            />
            <p className="flex px-1 font-medium">Updating please wait...</p>
          </div>
        )}
        <div className="mx-auto  w-full overflow-hidden">
          <div className="shadow overflow-hidden sm:rounded-md">
            <div className="px-4  bg-orange-200 sm:p-6">
              <div className="grid grid-cols-8 gap-1 py-2">
                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label
                    htmlFor="partnername"
                    className="block text-sm font-medium  text-gray-700"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    name="partnername"
                    id="partnername"
                    defaultValue={oldname}
                    onChange={e => setName(e.target.value)}
                    className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  ></input>
                </div>
                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone
                  </label>
                  <input
                    type="number" // Use text to control input length and format
                    name="phone"
                    id="phone"
                    defaultValue={oldphone}
                    onChange={handlePhoneChange}
                    className={`block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                      error ? 'border-red-500' : ''
                    }`}
                    required
                  />
                  {error && (
                    <p className="text-red-500 text-sm mt-1">{error}</p>
                  )}
                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium  text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    defaultValue={oldemail}
                    onChange={e => setEmail(e.target.value)}
                    className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  ></input>
                </div>
                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label className="flex text-green-700 text-xs font-sans">
                    <p className="text-black px-1"> Pre Value:</p>{' '}
                    {oldservicename}
                  </label>
                  <div className="relative flex items-center">
                    <select
                      name="serviceType"
                      required
                      className="block w-full shadow-sm sm:text-sm md:text-md mt-2 border-gray-300 rounded-md"
                      defaultValue=""
                      onChange={handleChange} // Attach handler
                    >
                      <option value="" disabled hidden>
                        Select service type
                      </option>
                      {resdata.map(service => (
                        <option key={service.id} value={service.servicename}>
                          {service.servicename}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label
                    htmlFor="notificationmsg"
                    className="flex  text-sm font-medium text-gray-700"
                  >
                    Date Of Enquiry:
                    <p className="text-green-800 px-1">
                      {new Date(olddate).toLocaleDateString('en-GB')}
                    </p>
                  </label>
                  <input
                    type="date"
                    name="notificationmsg"
                    id="notificationmsg"
                    value={servicedate}
                    onChange={e => setServicedate(e.target.value)}
                    className=" block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  ></input>
                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label
                    htmlFor="notificationmsg"
                    className="block text-sm font-medium  text-gray-700"
                  >
                    Description
                  </label>
                  <textarea
                    type="text"
                    name="notificationmsg"
                    id="notificationmsg"
                    defaultValue={olddes}
                    onChange={e => setDescription(e.target.value)}
                    className=" block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  ></textarea>
                </div>
                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label
                    htmlFor="notificationmsg"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Pincode
                  </label>
                  <input
                    type="number"
                    name="notificationmsg"
                    id="notificationmsg"
                    defaultValue={oldpincode}
                    onChange={handlePincode}
                    className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  />
                  {errorpin && (
                    <p className="text-red-500 text-xs mt-1">{errorpin}</p>
                  )}
                </div>
                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label
                    htmlFor="notificationmsg"
                    className="block text-sm font-medium  text-gray-700"
                  >
                    Address
                  </label>
                  <textarea
                    type="text"
                    name="notificationmsg"
                    id="notificationmsg"
                    defaultValue={oldaddress}
                    onChange={e => setAddress(e.target.value)}
                    className=" block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="flex items-center justify-center space-x-5 text-lg w-full">
        <button
          onClick={updateEnquiryform}
          className="flex justify-between py-1 px-2 border-transparent shadow-sm text-lg font-bold rounded-md text-white bg-gray-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 mt-2  border-2 border-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
            />
          </svg>

          <p className="mx-1">Update</p>
        </button>
      </div>

      <div
        className="cursor-pointer absolute top-0 right-0 mt-4 mr-5  border p-1 rounded-md shadow-md border-red-500 bg-red-200 transition duration-100 ease-in-out "
        onClick={props.onClose}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-label="Close"
          className="icon icon-tabler icon-tabler-x"
          width={20}
          height={20}
          viewBox="0 0 24 24"
          strokeWidth="2.5"
          stroke="#dc2626"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <line x1={18} y1={6} x2={6} y2={18} />
          <line x1={6} y1={6} x2={18} y2={18} />
        </svg>
      </div>
    </>
  );
};
export default UpdateEnquiry;
