import React, { useState, useContext, useEffect } from 'react';
import Main from '../../layout/Main';
import AuthContext from '../../store/authContext';
import mfaApi from '../../api/mfaApi';
import swal from 'sweetalert';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Services = () => {
  const authCtx = useContext(AuthContext);
  const [name, setName] = useState('');
  const [notifydata, setNotifydata] = useState([]);
  const [added, setadded] = useState(false);

  useEffect(() => {
    getNotification();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [added]);

  const addNotification = async e => {
    e.preventDefault();
    if (name === '') {
      swal('Please enter service name (non-empty values).');
    } else {
      try {
        const response = await mfaApi.post(
          `/urbanadmin/createservice`,
          {
            servicename: name,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authCtx.token}`,
            },
          },
        );
        if (response.status === 201) {
          setadded(true);
          swal(response.data.message);
        }
      } catch (error) {
        swal(error.response.data.message);
      }
    }
  };

  const getNotification = async () => {
    try {
      const response = await mfaApi.get(`/urbanadmin/getservice`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authCtx.token}`,
        },
      });
      if (response.status === 200) {
        setNotifydata(response.data.data);
      }
    } catch (error) {
      console.log(error);
      swal(error.response.data.message);
    }
  };

  return (
    <Main>
      <div>
        <section className="mx-auto  w-full">
          <div className="bg-gray-100 rounded-md shadow-2xl shadow-green-600 py-1 mb-1">
            <p className="mx-auto flex justify-center uppercase text-black lg:text-2xl md:text-xl sm:text-lg font-semibold ml-2 text-center">
              Services List
            </p>
          </div>
        </section>
      </div>
      <div>
        <section className="mx-auto  w-full">
          <div className="shadow  bg-yellow-100 overflow-hidden sm:rounded-md">
            <div className="px-4 bg-yellow-100">
              <div className="flex justify-center mt-3">
                <div>
                  <label
                    htmlFor="notificationmsg"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    name="notificationmsg"
                    id="notificationmsg"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    className=" block w-full shadow-sm sm:text-sm px-10 border-gray-300 rounded-md "
                    required
                  ></input>
                </div>
                <div className="flex justify-end py-2 px-3 m-3">
                  <button
                    onClick={addNotification}
                    className="inline-flex justify-center py-1 px-8 border border-transparent shadow-sm text-lg font-bold rounded-md text-white bg-gray-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Add +
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Show Skeleton if data has not yet been loaded */}
      {notifydata.length === 0 ? (
        <div className="my-2">
          <Skeleton count={10} height={30} />{' '}
          {/* Simple skeleton loading with 5 rows */}
        </div>
      ) : (
        <div className="flex flex-col">
          <div className=" overflow-x-auto relative">
            <div className="inline-block w-full">
              <div className="table-wrp block max-h-full lg:max-h-[32rem]">
                <table className="w-full">
                  <thead className="bg-gray-300 border-b sticky top-0">
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        S.no
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Service Name
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        URL Path
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Added By
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {notifydata?.map((data, index) => (
                      <tr key={data.id}>
                        <td className="px-2 py-1 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {index + 1 || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {data.servicename || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap">
                          <div className="text-sm  text-indigo-500  font-thin underline ">
                            /{data.href || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {data.addedby || 'N/A'}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </Main>
  );
};

export default Services;
