import React, { useState, Fragment, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Main from '../../layout/Main';
// import { ExclamationIcon } from "@heroicons/react/outline";
import AuthContext from '../../store/authContext';
import { ExclamationIcon } from '@heroicons/react/outline';
import { Dialog, Transition } from '@headlessui/react';
// import UserContext from "../../store/userContext";
import ReactLoading from 'react-loading';
import mfaApi from '../../api/mfaApi';
import swal from 'sweetalert';
import UpdateEmp from './updateEmp';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// import { Dialog, Transition } from "@headlessui/react";
const Employe = () => {
  const authCtx = useContext(AuthContext);
  const [id, setid] = useState();
  const [issearchLoading, setIssearchLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [pinocde, setPincode] = useState('');
  const [address, setAddress] = useState('');
  const [adharno, setAdharno] = useState('');
  const [joindate, setJoindate] = useState('');
  const [errorpin, setErrorpin] = useState('');
  const [remark, setRemark] = useState('');
  const [notifydata, setNotifydata] = useState([]);
  const [added, setadded] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isLoadingdelete, setisLoadingdelete] = useState(false);
  const [searchvalue, setSearchvalue] = useState('');
  console.log(searchvalue);
  const [error, setError] = useState('');
  const gettreasurytabledatabysearch = event => {
    setSearchvalue(event.target.value);
    // Additional logic or function calls if needed
  };

  const handlePincode = e => {
    const value = e.target.value;

    // Allow only digits and ensure the input does not exceed 10 digits
    if (/^\d*$/.test(value) && value.length <= 6) {
      setPincode(value);

      // Clear the error if input is valid
      if (value.length === 6) {
        setErrorpin('');
      } else {
        setErrorpin('');
      }
    } else if (value.length > 6) {
      setErrorpin('Pincode cannot exceed 6 digits');
    }
  };
  useEffect(() => {
    // Place your side effect code here
    // This code will run after the component renders
    getNotification();
    // You can perform cleanup by returning a function
    return () => {};
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, [searchvalue === '']);
  const gettreasurytabledatabysearchapi = async () => {
    try {
      setIssearchLoading(true);
      const response = await mfaApi.get(
        `/urbanadmin/searchemployee/${searchvalue}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authCtx.token}`,
          },
        },
      );
      if (response.status === 200) {
        setNotifydata(response.data.data);
        // setTotal(response.data);
        setIssearchLoading(false);
      } else {
        swal(response.data.message);
        setIssearchLoading(false);
        reloadPageWithDelay();
      }
    } catch (error) {
      swal(error.response.data.message);
      setIssearchLoading(false);
      reloadPageWithDelay();
    }
  };
  const handlePhoneChange = e => {
    const value = e.target.value;

    // Allow only digits and ensure the input does not exceed 10 digits
    if (/^\d*$/.test(value) && value.length <= 10) {
      setPhone(value);

      // Clear the error if input is valid
      if (value.length === 10) {
        setError('');
      } else {
        setError('');
      }
    } else if (value.length > 10) {
      setError('Phone number cannot exceed 10 digits');
    }
  };
  useEffect(() => {
    getNotification();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [added]);

  const addNotification = async e => {
    e.preventDefault();
    if (name === '' || phone === '' || servicename === '' || joindate === '') {
      swal('Please enter Name, phone, servicetype, and join date!');
    } else {
      setisLoading(true);
      try {
        const response = await mfaApi.post(
          `/urbanadmin/create_employee`,
          {
            fullname: name,
            email: email,
            phone: phone,
            servicetype: servicename,
            areapincode: pinocde,
            joiningdate: joindate,
            adharcardno: adharno,
            remark: remark,
            address: address,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authCtx.token}`,
            },
          },
        );
        if (response.status === 201) {
          setadded(true);
          await reloadPageWithDelay();
          setisLoading(false);
          swal(response.data.message);
        }
      } catch (error) {
        swal(error.response.data.message);
        setisLoading(false);
      }
      setisLoading(false);
    }
  };
  const getNotification = async () => {
    try {
      const response = await mfaApi.get(`/urbanadmin/getemployee`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authCtx.token}`,
        },
      });
      if (!response) {
        setisLoading(false);
      }
      if (response.status === 200) {
        setNotifydata(response.data.data);
      }
    } catch (error) {
      console.log(error);
      swal(error.response.data.message);
    }
  };

  //delete model
  let [isDeleteOpen, setIsDeleteOpen] = useState(false);

  function closeDeleteModal() {
    setIsDeleteOpen(false);
  }

  function openDeleteModal(id) {
    setIsDeleteOpen(true);
    setid(id);
  }
  function reloadPageWithDelay() {
    setTimeout(function () {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }, 2000); // 2000 milliseconds (2 seconds)
  }
  const deleteHandler = async () => {
    try {
      setisLoadingdelete(true);
      const response = await mfaApi.delete(`/urbanadmin/empdelete/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authCtx.token}`,
        },
      });
      if (response.status === 201) {
        swal('Delete Successfully');
        setadded(true);
        await reloadPageWithDelay();
        setisLoadingdelete(false);
      } else {
        swal(response.data.message);
        setisLoadingdelete(false);
      }
    } catch (error) {
      swal(error.response.data.message);
      setisLoadingdelete(false);
    }
    setIsDeleteOpen(false);
  };
  const [resenquiry, setResEnquiry] = useState({});
  console.log(resenquiry);

  let [isUpdateHomeOpen, setIsUpdateHomeOpen] = useState(false);
  function closeUpdateHomeModal() {
    setid();
    setResEnquiry({});
    setIsUpdateHomeOpen(false);
  }
  function openUpdateHomeModal(
    id,
    fullname,
    email,
    phone,
    servicetype,
    areapincode,
    joiningdate,
    status,
    adharcardno,
    address,
    remark,
  ) {
    setid(id); // Assuming setid updates some other state
    console.log(
      id,
      fullname,
      email,
      joiningdate,
      adharcardno,
      phone,
      servicetype,
      status,
      areapincode,
      address,
      remark,
    );

    // Update resenquiry with the new values
    setResEnquiry(prevState => ({
      ...prevState, // Spread the previous state in case you want to keep existing properties
      id: id,
      fullname: fullname,
      email: email,
      phone: phone,
      joiningdate: joiningdate,
      adharcardno: adharcardno,
      servicetype: servicetype,
      status: status,
      areapincode: areapincode,
      address: address,
      remark: remark,
    }));
    setIsUpdateHomeOpen(true); // Open the modal
  }
  const [resdata, setResdata] = useState([]);
  const getServicesDetails = async () => {
    try {
      const response = await mfaApi.get(`/urbanadmin/getservicepublic`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setResdata(response.data.data);
      } else {
        swal(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  const [servicename, setServicename] = useState('');
  useEffect(() => {
    // Place your side effect code here
    // This code will run after the component renders
    getServicesDetails();
    // You can perform cleanup by returning a function
    return () => {};
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, []);

  const handleChange = event => {
    const selectedValue = event.target.value;
    setServicename(selectedValue);
  };
  return (
    <Main>
      {' '}
      <div>
        <section className="mx-auto  w-full">
          <div className="bg-gray-100 rounded-md shadow-2xl shadow-green-600 py-1 mb-1">
            <p className="mx-auto flex justify-center uppercase text-black lg:text-2xl md:text-xl sm:text-lg font-semibold ml-2 text-center">
              {' '}
              Employee Details
            </p>
            {issearchLoading && (
              <div className="flex items-center justify-end ">
                <ReactLoading
                  type="spinningBubbles"
                  color="#ffffff"
                  height={'2%'}
                  width={'2%'}
                />
                <p className="flex px-1 font-medium text-white">
                  please wait...
                </p>
              </div>
            )}
          </div>
          <span className="flex justify-end text-white ">
            <input
              type="text"
              value={searchvalue}
              onChange={gettreasurytabledatabysearch}
              placeholder="Search....."
              className="text-left rounded-md text-sm text-black flex justify-end mr-2 w-1/4"
            />
            <div className="flex justify-end ">
              <button
                onClick={gettreasurytabledatabysearchapi}
                className="inline-flex justify-center py-1 px-2 border border-transparent shadow-sm text-lg font-bold rounded-md text-white bg-gray-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                  />
                </svg>
                <p className="mx-1">Search</p>
              </button>
            </div>
          </span>
        </section>
      </div>
      <div>
        <section className="mx-auto w-full">
          <div className="shadow bg-yellow-100 sm:rounded-md">
            <div className="p-1 bg-yellow-100">
              <div className="grid grid-cols-2 gap-2 md:grid-cols-5">
                <div>
                  <label
                    htmlFor="name"
                    className="flex text-sm font-normal text-gray-700"
                  >
                    Full Name{' '}
                    <p className="text-md  px-1 font-medium text-red-500">*</p>
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    className="w-full p-1 border-gray-300 rounded-md shadow-sm"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="flex text-sm font-normal text-gray-700"
                  >
                    Phone{' '}
                    <p className="text-md  px-1 font-medium text-red-500">*</p>
                  </label>
                  <input
                    type="text"
                    id="phone"
                    value={phone}
                    onChange={handlePhoneChange}
                    className={`w-full p-1 border-gray-300 rounded-md shadow-sm ${
                      error ? 'border-red-500' : ''
                    }`}
                    required
                  />
                  {error && (
                    <p className="text-red-500 text-sm mt-1">{error}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-normal text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    className="w-full p-1 border-gray-300 rounded-md shadow-sm"
                  />
                </div>
                <div>
                  <label className="text-gray-800 text-xs block ">
                    Service Type
                  </label>
                  <div className="relative flex items-center">
                    <select
                      name="serviceType"
                      required
                      className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      defaultValue=""
                      onChange={handleChange} // Attach handler
                    >
                      <option value="" disabled hidden>
                        Select service type
                      </option>
                      {resdata.map(service => (
                        <option key={service.id} value={service.servicename}>
                          {service.servicename}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-normal text-gray-700"
                  >
                    Remark
                  </label>
                  <textarea
                    type="text"
                    id="email"
                    value={remark}
                    onChange={e => setRemark(e.target.value)}
                    className="w-full p-1 border-gray-300 rounded-md shadow-sm"
                  />
                </div>
                <div>
                  <label
                    htmlFor="joindate"
                    className="flex text-sm font-normal text-gray-700"
                  >
                    Join Date{' '}
                  </label>
                  <input
                    type="date"
                    id="joindate"
                    value={joindate}
                    onChange={e => setJoindate(e.target.value)}
                    className="w-full p-1 border-gray-300 rounded-md shadow-sm"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="adharno"
                    className="flex text-sm font-normal text-gray-700"
                  >
                    Aadhar Number{' '}
                  </label>
                  <input
                    type="text"
                    id="adharno"
                    value={adharno}
                    onChange={e => setAdharno(e.target.value)}
                    className="w-full p-1 border-gray-300 rounded-md shadow-sm"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="pincode"
                    className="block text-sm font-normal text-gray-700"
                  >
                    Pincode{' '}
                  </label>
                  <input
                    type="number"
                    name="notificationmsg"
                    id="notificationmsg"
                    value={pinocde}
                    onChange={handlePincode}
                    className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  />
                  {errorpin && (
                    <p className="text-red-500 text-xs mt-1">{errorpin}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="address"
                    className="block  text-sm font-normal text-gray-700"
                  >
                    Address
                  </label>
                  <textarea
                    type="text"
                    id="address"
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                    className="w-full p-1 border-gray-300 rounded-md shadow-sm"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end p-2">
              <button
                onClick={addNotification}
                className="px-4 py-1 text-lg font-bold text-white bg-gray-600 rounded-md hover:bg-blue-600 focus:ring-2 focus:ring-blue-500"
              >
                Submit
              </button>
            </div>
          </div>
        </section>
      </div>
      {isLoadingdelete && (
        <div className="flex items-center justify-end my-2">
          <ReactLoading
            type="bars"
            color="#0143E2"
            height={'4%'}
            width={'4%'}
          />
          Deleting please wait....
        </div>
      )}
      {isLoading && (
        <div className="flex items-center justify-end my-2">
          <ReactLoading
            type="bars"
            color="#0143E2"
            height={'4%'}
            width={'4%'}
          />
          please wait....
        </div>
      )}
      {/* Show Skeleton if data has not yet been loaded */}
      {notifydata.length === 0 ? (
        <div className="my-2">
          <Skeleton count={10} height={30} />{' '}
          {/* Simple skeleton loading with 5 rows */}
        </div>
      ) : (
        <div className="flex flex-col">
          <div className=" overflow-x-auto relative">
            <div className="inline-block w-full">
              <div className="table-wrp block max-h-full lg:max-h-[28rem]">
                <table className="w-full">
                  <thead className="bg-gray-300 border-b sticky top-0">
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        S.no
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        update
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Worker Id
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Employement Status
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Full Name
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Phone
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Service Type
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Remark
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Join Date
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Pincode
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Address
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Adhar Number
                      </th>

                      <th
                        scope="col"
                        className="px-2 py-2 whitespace-nowrap text-left text-xs  text-gray-900 uppercase tracking-wider"
                      >
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {/* filteredData. */}
                    {notifydata?.reverse().map((data, index) => (
                      <tr key={uuidv4()}>
                        <td className="px-2 py-1 whitespace-nowrap">
                          <div className="text-sm font-normal text-gray-900">
                            {notifydata.length - index || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap">
                          <button
                            onClick={() =>
                              openUpdateHomeModal(
                                data.id,
                                data.fullname,
                                data.email,
                                data.phone,
                                data.servicetype,
                                data.areapincode,
                                data.joiningdate,
                                data.status,
                                data.adharcardno,
                                data.address,
                                data.remark,
                              )
                            }
                            className="text-thin flex  justify-start font-semibold px-3 py-2 text-red-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="gray"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </button>
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap">
                          <div className="text-sm font-normal text-gray-900">
                            {data.workerempi || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap">
                          <div
                            className={`flex text-sm font-normal ${
                              data.status === 'Active'
                                ? 'text-green-700'
                                : 'text-red-600'
                            }`}
                          >
                            {data.status || 'N/A'}
                          </div>
                        </td>

                        <td className="px-2 py-1 whitespace-nowrap">
                          <div className="text-sm font-normal text-gray-900">
                            {data.fullname || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap break-before-auto">
                          <div className="text-sm font-normal text-gray-900">
                            {data.phone || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap">
                          <div className="text-sm font-normal text-gray-900 ">
                            {data.email || 'N/A'}
                          </div>
                        </td>

                        <td className="px-2 py-1 whitespace-nowrap">
                          <div className="text-sm font-normal text-gray-900">
                            {data.servicetype || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 break break-after-all">
                          <div
                            className="text-sm font-medium text-gray-900 break-words w-full"
                            style={{ wordWrap: 'break-word', width: '40ch' }}
                          >
                            {data.remark || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap">
                          <div className="text-sm font-normal text-gray-900">
                            {data.joiningdate
                              ? new Date(data.joiningdate).toLocaleDateString(
                                  'en-GB',
                                )
                              : 'N/A'}
                          </div>
                        </td>

                        <td className="px-2 py-1 whitespace-nowrap">
                          <div className="text-sm font-normal text-gray-900">
                            {data.areapincode || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 break break-after-all">
                          <div
                            className="text-sm font-medium text-gray-900 break-words w-full"
                            style={{ wordWrap: 'break-word', width: '40ch' }}
                          >
                            {data.address || 'N/A'}
                          </div>
                        </td>
                        <td className="px-2 py-1 whitespace-nowrap">
                          <div className="text-sm font-normal text-gray-900">
                            {data.adharcardno || 'N/A'}
                          </div>
                        </td>

                        <td className=" flex justify-start text-sm text-gray-900 font-normal px-2 py-1 whitespace-nowrap">
                          <button
                            onClick={() => openDeleteModal(data.id)}
                            className="text-thin flex  justify-start font-semibold shadow-md rounded-md px-3 py-2 text-red-600 bg-blue-200"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="#dc2626"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      <Transition appear show={isDeleteOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto "
          onClose={closeDeleteModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen  align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-md transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-normal text-gray-900"
                      >
                        Delete
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-900">
                          Are you sure you want to delete ? All of your data
                          will be permanently removed. This action cannot be
                          undone.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-thin font-normal text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={deleteHandler}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-thin font-normal text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={closeDeleteModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isUpdateHomeOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto  "
          onClose={closeUpdateHomeModal}
        >
          <div className="min-h-screen px-4 text-center items-end">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen  align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-100"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full  max-w-4xl  py-2 px-6 my-8 overflow-hidden text-left align-bottom mt-32 transition-all transform bg-yellow-50 border-2 border-red-900   shadow-md rounded-xl ">
                <Dialog.Title
                  as="h4"
                  className="text-xl font-bold  text-center my-3 leading-6 text-indigo-900 "
                >
                  Update Employee Details
                </Dialog.Title>
                <UpdateEmp
                  enquiryid={id}
                  datares={resenquiry}
                  onClose={closeUpdateHomeModal}
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </Main>
  );
};

export default Employe;
