import React, { useState, useContext } from 'react';
import mfaApi from '../../api/mfaApi';
import AuthContext from '../../store/authContext';
import swal from 'sweetalert';
import ReactLoading from 'react-loading';
const UpdateWork = props => {
  let id = props.enquiryid;
  let oldresponsedata = props.datares;
  let oldstatus = oldresponsedata.status;
  let oldfullname = oldresponsedata.name;

  // Set the default value when the component mounts
  const authCtx = useContext(AuthContext);
  const [newstatus, setNewstatus] = useState('');
  const [remark, setRemark] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const [isLoadingdelete, setisLoadingdelete] = useState(false);

  function reloadPageWithDelay() {
    setTimeout(function () {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }, 2000); // 2000 milliseconds (2 seconds)
  }

  // EJ FILE UPLOAD API

  const updateEnquiryform = async e => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const response = await mfaApi.put(
        `/urbanadmin/updateworkstatus/${id}`,
        {
          newstatus: newstatus,
          remark: remark,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authCtx.token}`,
          },
        },
      );
      if (response.status === 201) {
        swal(response.data.message);
        setIsLoading(false);
        reloadPageWithDelay();
      }
    } catch (error) {
      swal({
        title: '🤦‍♂️',
        text: error.response.data.message,
        type: 'error',
        confirmButtonText: 'Cool',
      });
    }
    setIsLoading(false);
  };
  return (
    <>
      <section className="mx-auto  w-full overflow-hidden">
        {isLoading && (
          <div className="flex items-center justify-center my-5">
            <ReactLoading
              type="spinningBubbles"
              color="#0143E2"
              height={'6%'}
              width={'6%'}
            />
            <p className="flex px-1 font-medium">Updating please wait...</p>
          </div>
        )}
        <div className="mx-auto w-full overflow-hidden">
          <div className="shadow overflow-hidden sm:rounded-md">
            <div className="px-4 bg-orange-200 sm:p-4">
              {' '}
              {/* Adjusted padding for better spacing */}
              <div className="grid grid-cols-9 gap-4 py-2">
                {' '}
                {/* Modified grid structure */}
                {/* Full Name Section */}
                <div className="col-span-3 sm:col-span-3 lg:col-span-3">
                  <label
                    htmlFor="fullname"
                    className="block text-sm font-semibold text-gray-700"
                  >
                    Customer Name
                  </label>
                  <p className="text-gray-700 font-sans p-2 border border-gray-300 bg-gray-200 rounded-md">
                    {oldfullname}
                  </p>
                </div>
                {/* Status Section */}
                <div className="col-span-3 sm:col-span-3 lg:col-span-3">
                  <label
                    htmlFor="status"
                    className="flex text-sm font-semibold text-gray-700"
                  >
                    Previous Status:{' '}
                    <p className="flex px-2 text-black font-semibold pr-2">
                      {oldstatus}
                    </p>
                  </label>
                  <div className=" items-center">
                    <select
                      name="status"
                      id="status"
                      value={newstatus}
                      onChange={e => setNewstatus(e.target.value)}
                      className="block w-full shadow-sm sm:text-sm text-blue-900 font-semibold border border-gray-300 rounded-md"
                    >
                      <option value="">Select a status</option>
                      <option value="In Progress">In Progress</option>
                      <option value="Completed">Completed</option>
                      <option value="Pending Fund">Pending Fund</option>
                      <option value="Refund">Refund</option>
                      <option value="Hold Work">Hold Work</option>
                    </select>
                  </div>
                </div>
                <div className="col-span-3 sm:col-span-3 lg:col-span-3">
                  <label
                    htmlFor="notificationmsg"
                    className="block text-sm font-medium  text-gray-700"
                  >
                    Remark
                  </label>
                  <textarea
                    type="text"
                    name="notificationmsg"
                    id="notificationmsg"
                    value={remark}
                    onChange={e => setRemark(e.target.value)}
                    className=" block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="flex items-center justify-center space-x-5 text-lg w-full">
        <button
          onClick={updateEnquiryform}
          className="flex justify-between py-1 px-2 border-transparent shadow-sm text-lg font-bold rounded-md text-white bg-gray-600 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 mt-2  border-2 border-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
            />
          </svg>

          <p className="mx-1">Update</p>
        </button>
      </div>

      <div
        className="cursor-pointer absolute top-0 right-0 mt-4 mr-5  border p-1 rounded-md shadow-md border-red-500 bg-red-200 transition duration-100 ease-in-out "
        onClick={props.onClose}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-label="Close"
          className="icon icon-tabler icon-tabler-x"
          width={20}
          height={20}
          viewBox="0 0 24 24"
          strokeWidth="2.5"
          stroke="#dc2626"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <line x1={18} y1={6} x2={6} y2={18} />
          <line x1={6} y1={6} x2={18} y2={18} />
        </svg>
      </div>
    </>
  );
};
export default UpdateWork;
